import { ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import Footer from "../Footer";
import VectorDesign from "../../assets/images/Vector-design1.svg";
import useNavbarLogoColor from "../useNavbarLogoColor";
import Navbar from "../Navbar";
import { cookiePolicyContent } from "./content";

const commonStyles = {
  title: {
    color: "#1E2841",
    fontSize: "20px",
    fontFamily: "Sailec",
    fontWeight: "400",
  },
  content: {
    color: "#1E2841",
    fontSize: "16px",
    fontFamily: "Sailec",
    fontWeight: "400",
  },
  titleUppercase: {
    color: "#1E2841",
    fontSize: "20px",
    fontWeight: "750",
    textTransform: "uppercase",
  },
};

export default function CookiePolicy() {
  const backgroundColor = useNavbarLogoColor();

  const isDarkBG = useMemo(() => {
    return (
      backgroundColor !== "white" && backgroundColor !== "rgb(244, 244, 245)"
    );
  }, [backgroundColor]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <section
        spacing={0}
        style={{
          backgroundColor: "white",
          backgroundImage: `linear-gradient(180deg, rgba(30, 40, 65, 0) 50%,  white 100%), url(${VectorDesign})`,
          backgroundSize: "auto",
          backgroundPosition: "top left",
          backgroundRepeat: "no-repeat",
          color: "white",
        }}
      >
        <Navbar isDarkBG={isDarkBG} backgroundColor={backgroundColor} />

        <Stack
          //   minH={["672px", "800px"]}
          //   h={["672px", "800px"]}
          spacing={0}
          pt="156px"
        >
          <Stack
            align={"center"}
            spacing={0}
            pl={["32px", "32px", "32px", "120px"]}
            pr={["32px", "32px", "120px", "120px"]}
          >
            <Stack
              spacing={["40px", "80px "]}
              pt={["20px", "40px "]}
              pb={["50px", "100px "]}
              w={["100%", "100%", "100%", "100%", "1098px"]}
              // bg={'yellowgreen'}
            >
              <Stack spacing={"0px"} align={"center"}>
                <Text
                  className="futura"
                  textTransform={"uppercase"}
                  fontWeight={750}
                  fontSize={["24px", "24px", "46px", "46px"]}
                  lineHeight={["40px", "40px", "60px", "60px"]}
                  css={{
                    background:
                      "linear-gradient(90deg, #F75BB9 0%, #3953D4 80%)",
                    " -webkit-background-clip": "text",

                    "-webkit-text-fill-color": "transparent",
                    "background-clip": "text",
                    "text-fill-color": "transparent",
                  }}
                >
                  Cookie Policy
                </Text>
                <Text
                  fontWeight={400}
                  fontFamily="Sailec"
                  fontSize={["16px", "16px", "24px", "24px"]}
                  color={"#1E2841"}
                  lineHeight={["32px", "32px", "52px", "52px"]}
                >
                  Last updated: August 2023
                </Text>
              </Stack>
              {cookiePolicyContent.map((section, index) => (
                <Stack key={index} spacing={"12px"}>
                  <Text
                    className="futura"
                    lineHeight={"40px"}
                    style={
                      section.title === "What is this?"
                        ? commonStyles.title
                        : commonStyles.titleUppercase
                    }
                  >
                    {section.title}
                  </Text>

                  <Text
                    whiteSpace={"pre-line"}
                    lineHeight={"32px"}
                    style={commonStyles.content}
                  >
                    {section.content}
                  </Text>

                  {/* Render subsections if they exist */}
                  {section.subsections && (
                    <Stack spacing={"32px"} pt={"20px"}>
                      {section.subsections.map((subsection, subIndex) => (
                        <Stack key={subIndex} spacing={"12px"}>
                          <Text lineHeight={"40px"} style={commonStyles.title}>
                            {subsection.title}
                          </Text>

                          <Text
                            whiteSpace={"pre-line"}
                            lineHeight={"32px"}
                            style={commonStyles.content}
                          >
                            {subsection.content}
                          </Text>
                          {subsection.list &&
                            subsection.list.type === "unorderd" && (
                              <UnorderedList
                                spacing={2}
                                lineHeight={"32px"}
                                style={{
                                  paddingLeft: "52px",
                                  color: "#1E2841",
                                  fontSize: "16px",
                                  fontFamily: "Sailec",
                                  fontWeight: "400",
                                }}
                              >
                                {subsection.list.items.map((item, index) => (
                                  <ListItem key={index}> {item}</ListItem>
                                ))}
                              </UnorderedList>
                            )}
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </section>
      <Footer />
    </>
  );
}
