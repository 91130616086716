import { Button } from "@chakra-ui/react";
import React from "react";
import styles from "../../assets/styles/AppStyle";

export default function CButton3({ label, px, onClick, height }) {
  return (
    <Button
    onClick={onClick}
    style={styles.buttonStyle4}
    px={px}
    height={height}
    width={['100%','fit-content']}
    fontSize={'18px'}
    css={{
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        inset: 0,
        "border-radius": "100px",
        padding: "2px",
        background: "linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)",
        "-webkit-mask":
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        "-webkit-mask-composite": "xor",
        "mask-composite": " exclude",
      },
    }}
  >
    {label}
  </Button>
  );
}


