import { Stack, Text } from "@chakra-ui/react";
import React from "react";
import styles from "../assets/styles/AppStyle";

export default function OurEthos() {
  return (
    <section
    style={{
      color: "white",
      backgroundColor: "rgb(30, 40, 65)",
   
    }}
    >
    <Stack
      pl={['32px', '32px', '32px', '120px']}
      pr={["32px", '32px', '60px', '120px']}
      py={["60px", "60px", "60px", "140px"]}
      spacing={0}
      bg={"#1E2841"}
      align={"center"}
      justify={"center"}
    >
      <Stack
        spacing={["60px", "100px"]}
        maxW={["100%", "100%", "100%", "100%", "1098px"]}
      >
        <Stack
          spacing={"24px"}
        // maxW={"690px"}
        >
          <Text
            textTransform={"uppercase"}
            // style={styles.textHeading2}
            fontWeight={750}
            fontSize={["28px", "28px", "32px", "32px"]}
            lineHeight={["40px", "40px", "60px", "60px"]}
            className="futura"
            bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
            bgClip="text"
          >
            Our ethos
          </Text>
          <Text
            fontSize={["18px", "18px", "20px", "20px"]}
            lineHeight={["32px", "32px", "40px", "40px"]}
            color="#FFFFFF"
            w={['100%', '100%', '690px', '690px']}
          >
            Our patent pending platform powers our proposition and those of our
            clients and partners. It allows us to build digital products faster
            and more cost effectively than anyone else, without compromising on
            quality, scalability, security, or creativity.
          </Text>
        </Stack>
        <Stack direction={["column", "column", "row", "row"]} spacing={"24px"}>
          <Stack spacing={"20px"}>
            <Text style={styles.bodyText3}>Integrity & Honesty</Text>
            <Text opacity={0.7} style={styles.bodyText4}>
              We’ve been exactly where you are now, and we know how important
              trust, integrity and honesty is.
            </Text>
          </Stack>
          <Stack spacing={"20px"}>
            <Text style={styles.bodyText3}>Customer-Led</Text>
            <Text opacity={0.7} style={styles.bodyText4}>
              User experience is critical. We spend the time to get it right
              before we write even a single line of code.
            </Text>
          </Stack>
          <Stack spacing={"20px"}>
            <Text style={styles.bodyText3}>Improve Society</Text>
            <Text opacity={0.7} style={styles.bodyText4}>
              We believe in technology as a force for good and this impacts on
              who we choose to work with.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    </section>
  );
}
