import { Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import methodabg from "../assets/images/methodsbg.svg";
import createicon from "../assets/images/createicon.svg";
import thinkicon from "../assets/images/thinkicon.svg";
import useicon from "../assets/images/useicon.svg";
import styles from "../assets/styles/AppStyle";

function OurMethods(props) {
  return (
    <>
      <span
        id="methods"
        name="methods"
        style={{ position: "relative", top: "-156px" }}
      />
      <section
        style={{
          color: "white",
          backgroundColor: "rgb(30, 40, 65)",
        }}
      >
        <Stack
          name="methods"
          bg="#1E2841"
          w="100%"
          maxW="100%"
          mx="auto"
          h={["fit-content", "fit-content", "844px", "844px"]}
          bgImage={`linear-gradient(180deg, #1E2841 0%, rgba(30, 40, 65, 0) 50%),url(${methodabg})`}
          bgSize="auto"
          bgPosition="top left"
          bgRepeat={"no-repeat"}
          py={["60px", "60px", "60px", "140px"]}
          pl={["32px", "32px", "32px", "120px"]}
          pr={["32px", "32px", "60px", "120px"]}
          align={"center"}
        >
          <Stack
            spacing={["60px", "60px", "80px", "80px"]}
            maxW={["100%", "100%", "100%", "100%", "1098px"]}
          >
            <Stack spacing={"24px"}>
              <Text
                fontWeight={750}
                fontSize={["28px", "28px", "32px", "32px"]}
                textTransform={"uppercase"}
                className="futura"
                // style={styles.textHeading2}
                css={{
                  background:
                    " linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)",
                  " -webkit-background-clip": "text",

                  "-webkit-text-fill-color": "transparent",
                  "background-clip": "text",
                  "text-fill-color": "transparent",
                }}
              >
                Our Method
              </Text>
              <Text
                color={"white"}
                fontSize={["18px", "20px"]}
                fontWeight={400}
                w={["100%", "100%", "690px", "690px"]}
              >
                Our proprietary platform does the heavy lifting, we start all
                projects with our tried and tested AIME method.
              </Text>
            </Stack>
            <Stack
              direction={["column", "column", "row", "row"]}
              spacing={["40px", "40px", "24px", "24px"]}
            >
              <Stack spacing={["20px"]} align={"flex-start"}>
                <Image src={thinkicon} />
                <Text style={styles.bodyText3}>Think</Text>
                <Text opacity={0.7} style={styles.bodyText4}>
                  What does the product look like and what must it do.
                  Experience lead design right from the start gives you the
                  whole idea of what your product can achieve.
                </Text>
              </Stack>
              <Stack spacing={["20px"]} align={"flex-start"}>
                <Image src={createicon} />
                <Text style={styles.bodyText3}>Create</Text>
                <Text opacity={0.7} style={styles.bodyText4}>
                  Rapidly make it all actually work. Taking what we’ve achieved
                  in the Think phase, we bring the ideas and findings to life.
                </Text>
              </Stack>
              <Stack spacing={["20px"]} align={"flex-start"}>
                <Image src={useicon} />
                <Text style={styles.bodyText3}>Use</Text>
                <Text opacity={0.7} style={styles.bodyText4}>
                  Getting it in your hands asap so you can experience it and
                  input into the process incrementally and earlier than with any
                  other approach.
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </section>
    </>
  );
}

export default OurMethods;
