import {
  Box,
  Button,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";
import { ReactComponent as DarkLogo } from "../assets/images/DarkLogo.svg";
import styles from "../assets/styles/AppStyle";
import ModalGetInTouch from "./ModalGetInTouch";
import { useNavigate } from "react-router-dom";

function Navbar({ isDarkBG, backgroundColor }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <ModalGetInTouch isOpen={isOpen} onClose={onClose} />
      <HStack
        backdropFilter={"saturate(100%) blur(5px)"}
        position={"fixed"}
        top={0}
        right={0}
        left={0}
        spacing={0}
        w={"100%"}
        h={["156px"]}
        boxSizing="border-box"
        px={["32px", "60px"]}
        justify={"space-between"}
        zIndex={1000}
      >
        <Box onClick={() => navigate("/")} cursor={'pointer'}>
         
          {isDarkBG ? <Logo /> : <DarkLogo />}
        </Box>

        <Button
          onClick={onOpen}
          style={!isDarkBG ? styles.buttonStyle5 : styles.buttonStyle}
          px={"32px"}
          height={"52px"}
          // backgroundColor={isDarkBG ? "linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)" : "transparent"}
          color="#FFFFFF"
          fontWeight="400"
          fontSize="18px"
          lineHeight="28px"
          borderRadius={"100px"}
          w={["fit-content", "170px"]}
          css={{
            transition: "ease-in-out .2s",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              inset: 0,
              "border-radius": "100px",
              padding: "2px",
              background: "linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)",
              "-webkit-mask":
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              "-webkit-mask-composite": "xor",
              "mask-composite": " exclude",
            },
          }}
        >
          Get in touch
        </Button>
      </HStack>
    </>
  );
}

export default Navbar;
