import React, {  useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Stack,
  Text,
  Input,
  Textarea,
  useDisclosure,
  useToast,
  Button,
  Box,
} from "@chakra-ui/react";
import styles from "../assets/styles/AppStyle";
import CButton2 from "./common/CButton2";
import axios from "axios";
import QueryString from "qs";


const initialValues = {
  message: "",
  name: "",
  email: ""
}
export default function ModalGetInTouch({ isOpen, onClose }) {
  const { isOpen: isOpenThanks, onOpen: onOpenThanks, onClose: onCloseThanks } = useDisclosure();
  const [loading, setIsLoading] = useState(false);


  const [values, setvalues] = useState(initialValues);
  const toast = useToast();

  function toastifySuccess(title, status) {
    toast({
      duration: 3000,
      isClosable: true,
      position: 'bottom',
      render: () => (
        <Box fontWeight={700} borderRadius={'4px'} bg='white' p={3} color={status === "error" ? 'red.600' : "rgba(30, 40, 65)"}>
          {title}
        </Box>
      ),
    })
  }
  function isStrEmpty(strOrArr) {
    if (Array.isArray(strOrArr)) {
      return strOrArr.some(str => !str || str === '');
    } else {
      return !strOrArr || strOrArr === '';
    }
  }
  const handleSubmitForm = (event) => {
    event.preventDefault();
    // check if values are empty
    if (isStrEmpty([values.name, values.message, values.email])) {
      toastifySuccess("Enter all the fields", "error");
      return;
    }
    setIsLoading(true);

    var data = QueryString.stringify({
      'name': values.name,
      'email': values.email,
      'message': values.message
    });
    var config = {
      method: 'post',
      url: process.env.REACT_APP_AIME_EMAIL_URL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        toastifySuccess("Thank you. Your message has been received.","success");
        onClose();
        onOpenThanks();
        setIsLoading(false);
        setvalues(initialValues);
      })
      .catch(function (error) {
        console.log(error);
        toastifySuccess(error.message,"error");
        setIsLoading(false);
      });

  }
  const handleChange = (event) => {
    setvalues({
      ...values,
      [event.target.name]: event.target.value
    });
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay bg="rgba(30, 40, 65, 0.6)" backdropFilter="blur(6px)" />
        <ModalContent borderRadius={"24px"} p={"40px"} mx='24px'>
          <Flex justifyContent={"flex-end"}>
            <ModalCloseButton pos={"static"} />
          </Flex>
          <form id='contact-form' onSubmit={handleSubmitForm} noValidate>

            <ModalBody px={0} py={"32px"}>
              <Stack spacing={"24px"}>
                <Text
                  bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
                  bgClip="text"
                  className="futura"
                  textTransform={"uppercase"}
                  textAlign={"center"}
                  style={styles.textHeading3}
                >
                  Let’s work together
                </Text>
                <Text style={styles.bodyText5} textAlign={"center"}>
                  Drop us a line and we will get back to you as soon as possible.
                </Text>
              </Stack>

              <Stack pt={"32px"} spacing={"12px"}>
                <Text style={styles.textHeading4} textTransform={'uppercase'}     className="futura">Your Information</Text>
                <Input
                  required
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  h={"64px"}
                  borderRadius={"12px"}
                  placeholder="Full Name"
                  _placeholder={{ fontFamily: "Sailec" }}
                  style={styles.bodyText5}
                />
                <Input
                  required
                  type='email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  h={"64px"}
                  borderRadius={"12px"}
                  placeholder="Email Address"
                  _placeholder={{ fontFamily: "Sailec" }}
                  style={styles.bodyText5}
                />
              </Stack>
              <Stack pt={"32px"} spacing={"12px"}>
                <Text style={styles.textHeading4} textTransform={'uppercase'}      className="futura">Your message</Text>
                <Textarea
                  required
                  name='message'
                  value={values.message}
                  onChange={handleChange}
                  borderRadius={"12px"}
                  style={styles.bodyText5}
                  placeholder="Tell us about your project"
                  _placeholder={{ fontFamily: "Sailec" }}
                />
              </Stack>
            </ModalBody>

            <ModalFooter p={0} justifyContent={"center"}>
              <Button
                type="submit"
                isLoading={loading}
                style={styles.buttonStyle3} px={"52px"} height={"56px"}>
                Get in touch
              </Button>


            </ModalFooter>
          </form>

        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenThanks} onClose={onCloseThanks} size={"xl"}>
        <ModalOverlay bg="rgba(30, 40, 65, 0.6)" backdropFilter="blur(6px)" />
        <ModalContent borderRadius={"24px"} p={"40px"}  mx='24px'>
          <Flex justifyContent={"flex-end"}>
            <ModalCloseButton pos={"static"} />
          </Flex>

          <ModalBody px={0} py={"32px"}>
            <Stack spacing={"24px"}>
              <Text
                bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
                bgClip="text"
                className="futura"
                textTransform={"uppercase"}
                textAlign={"center"}
                style={styles.textHeading3}
              >
                Thanks
              </Text>
              <Text style={styles.bodyText5} textAlign={"center"}>
                We’ll be in touch
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter p={0} justifyContent={"center"}>
            <CButton2
              label={"Awesome"}
              px={"52px"}
              height={'56px'}
              onClick={onCloseThanks}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
