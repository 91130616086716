const styles = {
  buttonStyle: {
    backgroundColor: "transparent",
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
  },
  textHeading:{
    // fontFamily:'futura-lt-pro'
    fontWeight: "700",
    fontSize: "52px",
    lineHeight: "60px",
  },
  textHeadingMobile:{
    // fontFamily:'futura-lt-pro'
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "60px",
  },
  bodyText:{
    fontWeight: "400",
    fontSize: "28px",
    lineHeight: "44px",
    color: "#FFFFFF",
  },
  buttonStyle2:{
    backgroundColor: "transparent",
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "32px",
    border:'1px solid #FFFFFF',
    borderRadius:'100px'
  },
  textHeading2:{
    // fontFamily:'futura-lt-pro'
    fontWeight: 750,
    fontSize: "32px",
    lineHeight: "60px",
  },
  bodyText2:{
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "40px",
    color: "#FFFFFF",
  },
  bodyText3:{
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "44px",
    color: "#FFFFFF",
  },
  bodyText4:{
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#FFFFFF",
  },
  bodyText5:{
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "32px",
  },
  textHeading3:{
    // fontFamily:'futura-lt-pro'
    fontWeight: "750",
    fontSize: "24px",
    lineHeight: "32px",
  },
  textHeading4:{
    // fontFamily:'futura-lt-pro'
    fontWeight: "750",
    fontSize: "12px",
    lineHeight: "16px",
    
  },
  buttonStyle3: {
    background: "linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)",
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "32px",
    borderRadius:'100px'
  },
  buttonStyle5: {
    background: "linear-gradient(90deg, #F75BB9 0%, #3953D4 100%)",
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    borderRadius:'100px'
  },
  bodyText6:{
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "40px",
  },
  textHeading5:{
    // fontFamily:'futura-lt-pro'
    fontWeight: 750,
    fontSize: "20px",
    lineHeight: "40px",
    color:"#1E2841"
  },
  bodyText7:{
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    color:"#1E2841"
  },
  buttonStyle4: {
    color: "#1E2841",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    borderRadius:'100px',
    background:'transparent'
  },
  textHeading6:{
    fontFamily:'SailecItalic',
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "40px",
    color:'#1E2841',
    fontStyle:'italic',
    paddingBottom:'60px'
  },
  bodyText8:{
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
    color: "#1E284",
    opacity:0.7,
    fontStyle:'normal'
  },
};


export default styles;
