import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import './assets/styles/styles.css'
import CookiePolicy from "./components/CookiePolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/cookie-policy" element={<CookiePolicy/>}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
    </Routes>
  );
}

export default App;
