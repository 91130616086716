import { HStack, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as OnviLogo } from "../assets/images/OnviLogo.svg";
import { ReactComponent as CakeLogog } from "../assets/images/CakeLogo.svg";
import { ReactComponent as RiskSmartLogo } from "../assets/images/RiskSmartLogo.svg";
import { ReactComponent as YouPlusLogo } from "../assets/images/YouPlusLogo.svg";
import { ReactComponent as OctoPlusLogo } from "../assets/images/OctoplusLogo.svg";
import { ReactComponent as KidsWearLogo } from "../assets/images/KidswearLogo.svg";
import { ReactComponent as TotloftLogo } from "../assets/images/TotloftLogo.svg";
import { ReactComponent as UmbroLogo } from "../assets/images/UmbroLogo.svg";
import { ReactComponent as AgxiLogo } from "../assets/images/AgxiLogo.svg";
import { ReactComponent as AspandanLogo } from "../assets/images/AspadanLogo.svg";
import { ReactComponent as FWGLogo } from "../assets/images/FWGLogo.svg";
import { ReactComponent as TCSLogo } from "../assets/images/TCSLogo.svg";

export default function ClientAndVentures() {
  return (
    <section
    style={{
      color: "white",
      backgroundColor: "white",
   
    }}
    >
    <Stack maxW={"100%"} align={"center"} py={["60px", "60px", "60px", "140px"]}
      pl={["32px", "32px", "32px", "120px"]}
      pr={["32px", "32px", "32px", "120px"]}>
      <Stack
        w={["100%", "100%", "100%", "100%", "1098px"]}
        justify={"center"}
      >
        <Stack spacing={0} w="100%">
          <Stack spacing={"24px"} w="100%">
            <Text
              className="futura"
              textAlign={"center"}
              textTransform={"uppercase"}
              fontWeight={750}
              fontSize={["28px", "28px", "32px", "32px"]}
              lineHeight={["40px", "40px", "60px", "60px"]}
              bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
              bgClip="text"
            >
              Clients & Ventures
            </Text>
            <Text
              color="#1E2841"
              fontWeight={400}
              fontSize={["18px", "18px", "20px", "20px"]}
              lineHeight={["32px", "32px", "20px", "20px"]}
              textAlign={"center"}
            >
              Some of our recent projects, from fee-based engagements to joint
              ventures.
            </Text>
          </Stack>
          <Stack pt={"80px"} w="100%">
            <SimpleGrid
              w="100%"
              columns={[2, 3, 3, 4]}
              spacingX="100px"
              spacingY="30px"
              opacity={0.8}
            >
              <HStack spacing={0}>
                {" "}
                <OnviLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <CakeLogog />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <RiskSmartLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <YouPlusLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <OctoPlusLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <KidsWearLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <TotloftLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <UmbroLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <AgxiLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <AspandanLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <FWGLogo />
              </HStack>
              <HStack spacing={0}>
                {" "}
                <TCSLogo />
              </HStack>
            </SimpleGrid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>

    </section>
  );
}
