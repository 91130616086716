import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as ArrowDownIcon } from "../assets/images/IconArrowNarrowDown.svg";
import styles from "../assets/styles/AppStyle";
import ModalGetInTouch from "./ModalGetInTouch";
export default function HeroSection({ isDarkBG, backgroundColor }) {
  const { isOpen, onClose } = useDisclosure();

  return (
    <>
      <ModalGetInTouch isOpen={isOpen} onClose={onClose} />
      <Stack minH={["672px", "800px"]} h={["672px", "800px"]} spacing={0}>
        <Stack spacing={0}>
          {/* Top Menu */}

          <section
            style={{
              color: "white",
              // backgroundColor: "rgb(30, 40, 65)",
            }}
          >
            <Stack
              align={"center"}
              spacing={0}
              pl={["32px", "32px", "32px", "120px"]}
              pr={["32px", "32px", "120px", "120px"]}
              bgGradient={`linear-gradient(180deg, rgba(30, 40, 65, 0) 50%, #1E2841 100%)`}
            >
              <Stack spacing={0} w={["100%", "100%", "100%", "100%", "1098px"]}>
                <Stack spacing={"40px"} pt={["20px", "124px "]}>
                  <Stack spacing={"24px"}>
                    <Text
                      className="futura"
                      textTransform={"uppercase"}
                      fontWeight={750}
                      fontSize={["28px", "28px", "52px", "52px"]}
                      lineHeight={["40px", "40px", "60px", "60px"]}
                      // fontWeight={700}
                      // bgClip="text"
                      // fontSize={['28px','28px','52px','52px']}
                      // textTransform={"uppercase"}
                      css={{
                        background:
                          "linear-gradient(90deg, #F75BB9 0%, #3953D4 80%)",
                        " -webkit-background-clip": "text",

                        "-webkit-text-fill-color": "transparent",
                        "background-clip": "text",
                        "text-fill-color": "transparent",
                      }}
                    >
                      Innovation
                      <br /> without limits.
                    </Text>
                    <Text
                      //  fontWeight={400}
                      fontSize={["20px", "20px", "28px", "28px"]}
                      maxW={["100%", "660px"]}
                      color={"#ffffff"}
                    >
                      We flip the software development cycle on its head and put
                      the customer at the centre of everything.
                    </Text>
                  </Stack>
                  <a href="#methods">
                    <Button
                      style={styles.buttonStyle2}
                      width={["100%", "100%", "221px", "221px"]}
                      height={"56px"}
                      mb="120px"
                      bg="rgb(30, 40, 65)"
                    >
                      Our method
                    </Button>
                  </a>
                </Stack>
                <Stack spacing={0} align={"center"} pb={"40px"}>
                  <a href="#methods">
                    <ArrowDownIcon />
                  </a>
                </Stack>
              </Stack>
            </Stack>
          </section>
        </Stack>
      </Stack>
    </>
  );
}
