import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "../assets/styles/AppStyle";


const testmonials = [
  {
    description:"AIME has thrown the development rule book out the window, which can feel unusual, but the way AIME goes about producing technology is superb and will change your perception of what can be achieved in a short space of time.",
    name:"Joe Bush",
    jobtitle:"CEO",
    clientname:"The Chat Shop"
  },
  {
    description:"AIME is trying to crack real problems in an intelligent manner. The team are adaptable and their approach is collaborative, pragmatic and issue-focused, which I value. Furthermore, they’ll go above and beyond to bring a concept to life, however aren’t afraid to be honest if something isn’t possible.",
    name:"John Viner-Smith",
    jobtitle:"CEO",
    clientname:"Clausaly"
  },
  {
    description:"The AIME team helped us develop an easy to use, user focused app that could support professionals in the sporting industry (and beyond), to find relevant information for their Diversity and Inclusion journey. They were very flexible in their approach and helped us create an easily digestible tool, in record breaking time",
    name:"Giulian Zecchini",
    jobtitle:"",
    clientname:"FaceIT"
  },
  {
    description:"The team at AIME have been really easy to work with from the get-go on our Playbook For Change project....we were able to brief in our vision, develop, and finalise our tool in a way which made it comfortable for our project team to explain and share any feedback...we’re really pleased with the overall outcome and user experience that AIME have helped us create, to hopefully make a difference in the future.”",
    name:"Jonathan McCourt ",
    jobtitle:"",
    clientname:"GLD Umbro"
  },
  {
    description:"Before working with AIME, I thought I wasn’t going to be able to find a way to bring my vision to life. From day one, they’ve been super supportive, honest & extremely easy to work with. They go deeper than just treating you as another client of theirs, they truly care. Couldn’t recommend the team & AIME as a whole, enough!",
    name:"Callum Higgins",
    jobtitle:"CEO & Founder",
    clientname:"My Peers"
  },
  {
    description:"AIME have been an absolute game-changer for Aspedan. Not only do they have an agile and innovative way of working that really suits my startup; the team are extremely insightful and passionate about helping bring my vision to life. It has been fantastic to work with such a dedicated and creative group of people, and I’m really looking forward to continuing to work with AIME in the future!",
    name:"Madison Rose",
    jobtitle:"CEO & Founder",
    clientname:"Aspedan"
  },

]
export default function Feedback() {
  return (
    <>
    <section
    style={{
      backgroundColor: "rgb(244, 244, 245)",
     }}
  
    > 
    <Flex
      justify={"center"}
      py={["60px", "60px", "60px", "140px"]}
      px={["32px", "32px", "32px", "32px"]}
      bg={'rgb(244, 244, 245)'}
    >
      <Box w={["100%", "100%", "100%", "790px", "790px"]}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          autoPlay
          css
          renderIndicator={(
            onClickHandler,
            isSelected,
            index,
            label,
            active
          ) => {
            return (
              <button
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "100%",
                  backgroundColor: isSelected ? "#1E2841" : "#C9CBD1",
                  marginRight: "20px",
                }}
                onClick={onClickHandler}
              ></button>
            );
          }}
        >
          {testmonials.map(testimonial =>(
          <div>
            <text style={styles.textHeading6}>
              “{testimonial.description}”
              <Text pt={"24px"} style={styles.bodyText8}>
               {testimonial.name}, {testimonial.clientname}
              </Text>
            </text>
          </div>
            
          ))}
        </Carousel>
      </Box>
    </Flex>
     
    </section>
    </>
  );
}
