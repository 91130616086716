import { Button } from "@chakra-ui/react";
import React from "react";
import styles from "../../assets/styles/AppStyle";

export default function CButton2({ label, px, onClick, height, loading }) {
  return (
    <Button 
    type="submit"
    isLoading={loading}
    onClick={onClick}
     style={styles.buttonStyle3} px={px} height={height}>
      {label}
    </Button>
  );
}
