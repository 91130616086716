import { Image, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import styles from "../assets/styles/AppStyle";
import AndreasImage from "../assets/images/Andreas.svg";
import AlanImage from "../assets/images/Alan.svg";
import MattImage from "../assets/images/Matt.svg";
import DerekImage from "../assets/images/Derek.svg";
import OlgaImage from "../assets/images/Olga.svg";
import SamImage from "../assets/images/Sam.svg";
import AmanImage from "../assets/images/Aman.svg";
const teamMembers = [
  {
    name: "Andreas Pouros",
    destination: "Founder & CEO",
    imgSrc: AndreasImage,
  },
  {
    name: "Alan Harrison",
    destination: "Founder & CPO",
    imgSrc: AlanImage,
  },
  {
    name: "Matt Whiteway",
    destination: "Managing Director",
    imgSrc: MattImage,
  },
  {
    name: "Derek Boyles",
    destination: "Customer Success Director",
    imgSrc: DerekImage,
  },
  {
    name: "Olga Izmaylova",
    destination: "Ops Director, AIME Ventures",
    imgSrc: OlgaImage,
  },
  {
    name: "Sam Angeli",
    destination: "Chief Design Officer",
    imgSrc: SamImage,
  },
  {
    name: "Aman Soomro",
    destination: "Development Manager",
    imgSrc: AmanImage,
  },
];

export default function Team() {
  return (
    <section
    style={{
      color: "white",
      backgroundColor: "white",
   
    }}
    >
    <Stack
      maxW={"100%"}
      align={"center"}
      py={["60px", "60px", "60px", "140px"]}
      pl={["32px", "32px", "32px", "120px"]}
      pr={["32px", "32px", "32px", "120px"]}
    // bg={"green"}
    >
      <Stack
        w={["100%", "100%", "100%", "100%", "1098px"]}
        spacing={0}
        bg={"#FFFFFF"}
        align={"center"}
      >
        <Stack spacing={"24px"} maxW={"790px"}>
          <Text
            textTransform={'uppercase'}
            textAlign={"center"}
            bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
            bgClip="text"
            fontWeight={750}
            fontSize={["28px", "28px", "32px", "32px"]}
            lineHeight={["40px", "40px", "60px", "60px"]}
            className="futura"
          >
            Meet The Team
          </Text>
          <Text
            textAlign={"center"}
            fontWeight={400}
            fontSize={["18px", "18px", "20px", "20px"]}
            lineHeight={["32px", "32px", "40px", "40px"]}
            color={'#1E2841'}
          >
            Our team includes successful entrepreneurs, software developers,
            creatives, and product specialists, all with a common passion for
            disrupting industries and making a difference.
          </Text>
        </Stack>
        <Wrap
          spacing={"0px"}
          pt={["20px", "20px", "0px", "0px", "0px"]}
          justify={"center"}
        >
          {teamMembers.map((obj, index) => (
            <WrapItem
              key={index}
              // bg={'green'}
              px={["0px", "0px", "12px", "12px", "9px"]}
              pt={["60px", "60px", "80px", "80px", "80px"]}
            >
              <User
                name={obj.name}
                destination={obj.destination}
                imgSrc={obj.imgSrc}
              />
            </WrapItem>
          ))}
        </Wrap>
      </Stack>
    </Stack>
    </section>
  );
}

function User({ name, destination, imgSrc }) {
  return (
    <Stack
      spacing={"20px"}
      minW={["311px", "311px", "256px", "256px", "256px"]}
      align={"center"}
    >
      <Image src={imgSrc} />
      <Stack spacing={0}>
        <Text className="futura" textTransform={'uppercase'} style={styles.textHeading5} textAlign={"center"}>
          {name}
        </Text>
        <Text style={styles.bodyText7} textAlign={"center"}>
          {destination}
        </Text>
      </Stack>
    </Stack>
  );
}
