import { Divider, Stack } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import HeroSection from "./HeroSection";
import OurEthos from "./OurEthos";
import ClientAndVentures from "./ClientAndVentures";
import Footer from "./Footer";
import OurSolutions from "./OurSolutions";
import OurMethods from "./OurMethods";
import Team from "./Team";
import Feedback from "./Feedback";
import VectorDesign from "../assets/images/Vector-design.svg";
import useNavbarLogoColor from "./useNavbarLogoColor";
import Navbar from "./Navbar";

export default function Home() {
  const backgroundColor = useNavbarLogoColor();

  const isDarkBG = useMemo(() => {
    return backgroundColor !== "white" && backgroundColor !== "rgb(244, 244, 245)";
  }, [backgroundColor]);
 

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);
  return (
    <Stack spacing={0} 
    bg={"#1E2841"}
    bgImage={`linear-gradient(180deg, rgba(30, 40, 65, 0) 50%, #1E2841 100%),url(${VectorDesign})`}
    bgSize='auto'
    bgPosition="top right"
    bgRepeat={'no-repeat'}
    >
      <Navbar  isDarkBG={isDarkBG} backgroundColor={backgroundColor}/>
      <Stack  spacing={0} pt="156px">
      <HeroSection isDarkBG={isDarkBG} backgroundColor={backgroundColor} />
      <OurEthos />
      <ClientAndVentures />
      <Feedback />
      <OurMethods />
      <OurSolutions />
      <section
        style={{
          color: "white",
          backgroundColor: "white",

        }}
      >
        <Divider w={['311px', '311px', '790px', '790px']} color={'#1E28414D'} bg={'#1E28414D'} alignSelf={'center'} />
      </section>
      <Team />
      <Footer />

      </Stack>
    </Stack>
  );
}
