export const cookiePolicyContent = [
  {
    title: "What is this?",
    content:
      "Most privacy laws, including the GDPR, require that where a website uses cookies to collect information, the legal obligation is for the website owner to inform the website visitor what information is collected, and what is done with that information. Therefore, if a website uses cookies to collect information, the website owner must have a cookie policy.",
  },
  {
    title: "Introduction",
    content:
      "The AIME Group Ltd.(“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit this website including any other media form, or mobile application related or connected thereto to help customise the website and improve your experience.\n\n We reserve the right to make changes to this Cookie Policy at any time and for any reason. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the website, and you waive the right to receive specific notice of each such change or modification.You are encouraged to periodically review this Cookie Policy to stay informed of updates.\n\n You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the website after the date such revised Cookie Policy is posted.",
  },
  {
    title: "Use of Cookies",
    content:
      "A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the website. We use cookies on the website to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the website, facilitate purchase procedures where appropriate, and track the pages you visit. Cookies help us understand how the website is being used and enable us to improve your user experience.",
  },
  {
    title: "Types of Cookies",
    content:
      "The following types of cookies may be used when you visit the Site:",

    subsections: [
      {
        title: "Advertising Cookies",
        content:
          "Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies are linked to a computer and do not gather any personal information about you.",
      },
      {
        title: "Analytics Cookies",
        content:
          "Analytics cookies monitor how users reached this website, and how they interact with and move around once on the website. These cookies let us know which features are working the best and what features can be improved.",
      },
      {
        title: "Our Cookies",
        content:
          "Our cookies are “first-party cookies” and can be either permanent or temporary. These are necessary cookies, without which the website won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser but may affect the functionality of the website.",
      },
      {
        title: "Personalisation Cookies",
        content:
          "Personalisation cookies are used to recognise repeat visitors to the website. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the website.",
      },
      {
        title: "Security Cookies",
        content:
          "Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorised parties.",
      },

      {
        title: "Site Management Cookies",
        content:
          "Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.",
      },
      {
        title: "Third-Party Cookies",
        content:
          "Third-party cookies may be placed on your computer when you visit the website via companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you.\n\n These cookies can be manually disabled in your browser. If you do decide to disable cookies manually, you may not be able to use all of our website services.We are obliged to list the third party services providers who place third-party cookies on your device when you visit our website. You can see these below:",

        list: {
          type: "unorderd",
          items: [
            "Google Ads",
            "Google Analytics",
            "GA4",
            "Google Search Console",
            "Facebook Business",
          ],
        },
      },
    ],
  },
  {
    title: "CONTROL OF COOKIES",
    content: (
      <>
        Most browsers are set to accept cookies by default. However, you can
        remove or reject cookies in your browser’s settings and / or via the
        cookie notice. <br></br>
        <br></br>
        Please be aware that such action could affect the availability and
        functionality of the website.<br></br>
        <br></br>
        For more information on how to control cookies, check your browser or
        device’s settings for how you can control or reject cookies.In addition,
        you may opt-out of some third-party cookies through the{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://optout.networkadvertising.org/?c=1#!%2F"
          target="_blank"
          rel="noreferrer"
        >
          Network Advertising Initiative’s Opt-Out Tool
        </a>{" "}
      </>
    ),
  },
  {
    title: "OTHER TRACKING TECHNOLOGIES",
    content:
      "In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the website to help customise the website and improve your experience. A “web beacon” or “pixel tag” is a tiny object or image embedded in a web page or email. It is used to track the number of users who have visited particular pages. It will collect only a limited set of data, such as a cookie ref ID, time and date of page view, website page address, and a description of the page on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.",
  },
  {
    title: "Privacy Policy",
    content:
      "For more information about how we use information collected by cookies and other tracking technologies, please refer to our Privacy Policy. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using this website, you agree to be bound by this Cookie Policy and our Privacy Policy.",
  },
  {
    title: "Contact Us",
    content:
      "If you have questions or comments about this Cookie Policy, please contact us via our website.\n\nCreated By: The AIME Group Ltd",
  },
];
