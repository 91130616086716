import { useState, useEffect } from "react";

function useNavbarLogoColor() {
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    // add a scroll event listener to the window to detect when the background color changes
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    // remove the event listener when the component unmounts to avoid memory leaks
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    // find the section that is currently under the navbar
    const sections = document.querySelectorAll("section");
    let currentSectionIndex = 0;
    for (let i = 0; i < sections.length; i++) {
      const rect = sections[i].getBoundingClientRect();
      if (rect.top <= 78 && rect.bottom > 78) {
        //Here 50 is half the height of the navbar
        currentSectionIndex = i;
        break;
      }
    }

    // update the background color state variable based on the current section
    const currentBackgroundColor =
      sections[currentSectionIndex].style.backgroundColor;
    setBackgroundColor(currentBackgroundColor);
  }

  return backgroundColor;
}

export default useNavbarLogoColor;