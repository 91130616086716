import {  Image, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import styles from "../assets/styles/AppStyle";
import ModalGetInTouch from "./ModalGetInTouch";
import CButton3 from "./common/CButton3";
import mvprun from "../assets/images/mvprun.svg";
import aimeenterprise from "../assets/images/aimeenterprice.svg";
import aimeventures from "../assets/images/aimeventures.svg";
import aimesaas from "../assets/images/aimesaas.svg";
import betaicon from "../assets/images/betaicon.svg";

function OurSolutions({ sectionRef }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <section
    style={{
      color: "white",
      backgroundColor: "white",
   
    }}
    >
    <Stack
      maxW={"100%"}
      align={"center"}
      pl={['32px', '32px', '32px', '120px']}
      pr={["32px", '32px', '60px', '120px']}
      py={["32px", "32px", "32px", "140px"]}
      backgroundColor="white"
    >
      <ModalGetInTouch isOpen={isOpen} onClose={onClose} />
      <Stack
        w={["100%", "100%", "100%", "100%", "1098px"]}
        spacing={0}
        ref={sectionRef} backgroundColor="white"
      // bg={"#FFFFFF"}
      >
        <Stack spacing={["60px", "100px"]}>
          <Text
            fontSize={["28px", "28px", "32px", "32px"]}
            fontWeight={750}
            textTransform={"uppercase"}
            className="futura"
            style={styles.textHeading2}
            css={{
              background: "linear-gradient(90deg, #F75BB9 0%, #3953D4 35%)",
              " -webkit-background-clip": "text",

              "-webkit-text-fill-color": "transparent",
              "background-clip": "text",
              "text-fill-color": "transparent",
            }}
          >
            Our Solutions
          </Text>
          {/* mvp.run  */}
          <Stack
            spacing={["40px", "78px"]}
            direction={["column-reverse", "column-reverse", "row", "row"]}
          >
            <Stack spacing={"20px"} w="100%">
              <Text fontSize={"28px"} color="#1E2841">
                MVP.RUN
              </Text>
              <Text fontSize={"18px"} color="#1E2841" opacity={'0.7'}>
                The world's first and only specialist in the creation of Minimum
                Viable Products (MVPs) and Proof of Concepts (POCs) at speed,
                allowing entrepreneurs and businesses to test their product in
                the market with minimal financial exposure.
              </Text>
              <CButton3
                label={"Enquire"}
                px={"32px"}
                height={"52px"}
                color="#1E2841"
                onClick={onOpen}
              />
            </Stack>
            <Stack w="100%">
              <Image src={mvprun} />
            </Stack>
          </Stack>
          {/* AIME Enterprise */}
          <Stack
            spacing={["40px", "78px"]}
            direction={["column", "column", "row", "row"]}
          >
            <Stack w="100%">
              <Image src={aimeenterprise} />
            </Stack>
            <Stack spacing={"20px"} w="100%">
              <Text fontSize={"28px"} color="#1E2841">
                AIME Enterprise
              </Text>
              <Text fontSize={"18px"} color="#1E2841" opacity={'0.7'}>
                Turning your MVP or POC (built with MVP.RUN or another party)
                into a constantly evolving and improving product with ongoing,
                weekly sprints to add new functionality, pivot, and/or improve
                based on user feedback.
              </Text>
              <CButton3
                label={"Enquire"}
                px={"32px"}
                height={"52px"}
                color="#1E2841"
                onClick={onOpen}
              />
            </Stack>
          </Stack>
          {/* AIME Ventures */}
          <Stack
            spacing={["40px", "78px"]}
            direction={["column-reverse", "column-reverse", "row", "row"]}
          >
            <Stack spacing={"20px"} w="100%">
              <Text fontSize={"28px"} color="#1E2841">
                AIME Ventures
              </Text>
              <Text fontSize={"18px"} color="#1E2841" opacity={'0.7'}>
                We partner with domain experts to build new and exciting digital
                products and businesses. We undertake the role of CTO,
                development house and Chair, freeing you to run the business as
                majority shareholder and CEO.
              </Text>
              <CButton3
                label={"Enquire"}
                px={"32px"}
                height={"52px"}
                color="#1E2841"
                onClick={onOpen}
              />
            </Stack>
            <Stack w="100%">
              <Image src={aimeventures} />
            </Stack>
          </Stack>
          {/* AIME SaaS */}
          <Stack
            spacing={["40px", "78px"]}
            direction={["column", "column", "row", "row"]}
          >
            <Stack w="100%">
              <Image src={aimesaas} />
            </Stack>
            <Stack spacing={"20px"} w="100%" align={'flex-start'}>
              <Stack spacing={0}>
                <Image src={betaicon} w='52px' h='24px' />
                {/* <Center bg='#3953D4'w='52px' h='24px'borderRadius={'8px'}>
  <Text fontSize={'12px'} fontWeight={700}color={'white'}>BETA</Text>
</Center> */}
                <Text fontSize={"28px"} color="#1E2841">
                  AIME SaaS
                </Text>
              </Stack>
              <Text fontSize={"18px"} color="#1E2841" opacity={'0.7'}>
                Our low-code platform that underpins much of our advantage as
                digital product and business builders, made available to anyone
                as a Software as a Service (SaaS) provision with a variety of
                license types and payment models.
              </Text>
              <CButton3
                label={"Enquire"}
                px={"32px"}
                height={"52px"}
                color="#1E2841"
                onClick={onOpen}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    </section>
  );
}

export default OurSolutions;
