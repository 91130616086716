import { Stack, Text } from "@chakra-ui/react";

import { Link } from "react-router-dom";

export const privacyPolicyContent = [
  {
    content:
      'We at The AIME Group Ltd. (hereafter referred to as “AIME”) are dedicated to keeping your information private. This privacy statement will explain how we handle your personal information when you use our Platform or visit our website.\n\nAdditionally, it informs you of your legal protections and rights to privacy. In order to completely understand how and why we are using your data, it is crucial that you read this privacy statement as well as any other privacy statements or fair processing statements we may provide on occasion when we are collecting or processing personal data about you.\n\n We are committed to being responsible custodians of the personal information that we collect in the course of operating our business.\n\nThis Privacy Policy ("Policy") explains in full how, in our capacity as a data controller, we collect, use, disclose, or otherwise process and safeguard personal information as part of running our business and providing services to our clients and customers.\n\nInformation that identifies, relates to, characterises, or may reasonably be linked, directly or indirectly, to a specific natural person is referred to in this Policy as "personal information." It excludes data that is regarded under applicable law as anonymous, de-identified, or aggregated. Please refer to our Data Privacy Notice on our web site for applicable definitions.'
  },
  {
    title: "Who We Are",
    content: (
      <>
        AIME is the data controller (collectively referred to as "we", "us" or
        "our" in this privacy policy) and responsible for the processing of your
        personal data also referred to as personal identifiable (PI) data.
        <br /> <br />
        If you have any questions about this privacy policy or our privacy
        practices, please contact us in the following ways:
        <Stack spacing={"12px"} my={"12px"} ml={"32px"}>
          <Text
            px={"12px"}
            py={"4px"}
            lineHeight={"32px"}
            style={{
              width: "fit-content",
              blockSize: "fit-content",
              background: "rgba(30, 40, 65, 0.05)",
              borderRadius: 8,
              color: "#1E2841",
              fontSize: "16px",
              fontFamily: "Sailec",
              fontWeight: "400"
            }}
          >
            <span>Email address:</span>
            <a
              href="mailto:info@aime.global"
              style={{
                textDecoration: "underline",
                paddingLeft: "8px"
              }}
            >
              info@aime.global
            </a>
          </Text>
        </Stack>
        <br></br>
        Any information relating to an identified or identifiable individual is
        referred to as "Personal Data," "Personal Information," or "information
        about you" as used in this privacy statement. This includes, but is not
        limited to, your name, last name, company name, email address, phone
        number, date of birth, card type (credit or debit), card number,
        expiration date, postal code, as well as information about your past
        purchases and payments.<br></br>
        <br></br>
        The purpose of this privacy statement is to ensure that you are aware of
        the Personal Data we gather about you, its uses, and its disclosures. To
        summarise, we want to let you know how we respect your privacy and what
        rights you have in relation to the Personal Data we gather and use about
        you.<br></br>
        <br></br>
        For further clarifications on this Privacy Policy, questions, or
        concerns, please do not hesitate to contact us.
      </>
    )
  },
  {
    title: "1. Personal Data we collect from you",
    content: (
      <>
        <Text mb={"12px"} style={{ lineHeight: "40px", fontSize: "20px" }}>
          When do we collect your Personal Information?
        </Text>
        We may collect data, including your personal data, through our
        interactions with you, and when you undertake one or more of the
        following:
      </>
    ),

    subsections: [
      {
        list: {
          type: "unordered",
          items: [
            "Register, or complete or submit information or forms on our website",
            "Download content on our website",
            "Provide us with feedback and information via phone, email, social media, etc.",
            "Answer a survey",
            "Subscribe to our newsletters or other content-related material",
            "Use a chat function / chatbot",
            "Use forums or leave comments on our website",
            "Purchase or use our products and/or services"
          ]
        }
      },
      {
        content: (
          <>
            Online data about you may also come through cookies and other
            similar technologies (such pixel tags and device identifiers) used
            on our site or other websites. Please refer to our{" "}
            <Link to="/cookie-policy">
              <span style={{ color: "blue" }}>Cookie Policy</span>
            </Link>{" "}
            for more details on cookies and related technologies.<br></br>
            <br></br>We may collect now and, in the future, the following
            categories of personal information listed below from consumers, for
            commercial and/or business purposes under the GDPR and other
            applicable jurisdictions:
          </>
        )
      },
      {
        list: {
          type: "unordered",
          items: [
            <>
              <strong>
                Identifiers and registration contact information:{" "}
              </strong>
              such as name, e-mail address, postal address, telephone number,
              account name, online identifier, Internet Protocol (IP) address,
              or other similar identifiers.
            </>,
            <>
              <strong> Customer records information: </strong> such as name,
              signature, social security number, physical characteristics or
              description, address, telephone number, passport number, bank
              account number, credit or debit card number, other financial
              information.
            </>,
            <>
              <strong> Commercial information: </strong>includes delivery
              information, records of goods purchased, obtained, or considered,
              as well as other consumer histories or patterns and transactions
              with us.
            </>,
            <>
              <strong>
                Internet or other electronic network activity information:{" "}
              </strong>
              information about a consumer's engagement with an Internet
              website, application, or advertisement, as well as device
              information and device identifiers, browsing type and language,
              browsing history, search history, and data connection and use
              information, such as information you download.
            </>,
            <>
              <strong>Geolocation data: </strong>such as city, state, zip code,
              etc. related to your IP address.
            </>,
            <>
              <strong>Inferences </strong>on your preferences and
              characteristics.
            </>,
            <>
              <strong> Other information </strong>includes anything you
              voluntarily choose to share with us about how you use our
              services, surveys we send you, etc. Customer information includes
              inquiries and other messages you send to us directly via online
              forms, chats, e-mail, phone, or postal mail, as well as summaries
              or voice recordings of your conversations with customer service.
            </>
          ]
        }
      }
    ]
  },
  {
    title: "2. Personal Data we collect automatically",
    content:
      "Our website automatically collects certain data, through automatic data collection tools such as cookies, beacons, among others. These tools automatically collect the following information:",

    subsections: [
      {
        title: "Technical Information",
        content:
          "Technical data, such as, but not limited to, browser type, operating system, device details, online identifiers like cookie data and Internet Protocol address (IP), domain name, referral URL, time zone setting, and/or visit time stamp."
      },
      {
        title: "Usage Data",
        content:
          "Usage Data is the information we collect about how the Website is accessed and used by our visitors. It may comprise your computer's Internet Protocol address (IP address), browser version, list of pages you have viewed on our website, the time spent on those pages, the date and duration of your visit, and other analytical data."
      }
    ]
  },
  {
    title: "3. How do we use your Personal Data",
    content: "Such business purposes may be:",

    subsections: [
      {
        list: {
          type: "unordered",
          items: [
            "To fulfil the purpose for which the Personal Information is provided",
            "To provide you with information, products and/or services that you request from us",
            "To communicate and respond to your requests and inquiries to us",
            "In order to engage in business with customers and fulfil requests for our services",
            "To check in with our goods and/or services after they've been delivered (live chat, email, or phone inquiries)",
            "To provide consumers with e-mail alerts, event registrations, and other notices concerning our products and/or services, or events or news that might be of interest to the consumer",
            "To manage and enhance our site's technical and functional aspects, as well as to provide its information to our clients or others",
            "To evaluate, develop, and enhance how our site is used and functions",
            "To modify, enhance, or improve our services and/or provision of such services to our clients",
            "As necessary and appropriate to protect the rights, property of AIME, our clients, or others",
            "For the purpose of fulfilling our obligations and enforcing our rights stemming from any contract we have with customers, clients or other contractual third parties, including billing and collections",
            "For testing, research, analysis, and product and services development",
            "To manage the security of our website",
            "To comply with applicable law and regulations to operate our business",
            "To respond to law enforcement requests as required by applicable law, court order, or governmental regulations",
            "As described to the consumer when collecting the consumer’s Personal Information, or as otherwise as set forth by the GDPR Data Laws and other Jurisdictions"
          ]
        }
      },
      {
        content:
          "We do not gather additional categories of personal data, nor do we use the data we have acquired for purposes other than those listed in this privacy statement. The Personal Information we gather enables us to send you periodic emails about our products and/or services and to keep you updated on AIME’s most recent product announcements, software updates, and upcoming events. It also enables us to tailor the content you see on our website, making it more applicable for you and enabling us to deliver the kind of information and product offerings in which you are most interested where appropriate and applicable."
      }
    ]
  },
  {
    title: "4. Legal Basis for processing your Personal Data",
    content:
      "We rely on the following legal bases to collect and process your Personal Data under applicable GDPR Data Laws and other Jurisdictions:",

    list: {
      type: "unordered",
      items: [
        <>
          <strong>Consent: </strong>where required to obtain your consent, we
          will do so. For example, certain jurisdictions require us to obtain
          your consent to process precise location information{" "}
        </>,
        <>
          <strong>Performance of a contract: </strong>where processing is
          necessary for the performance of a contract with you. We may need to
          collect and use your personal information to enter into a contract
          with you or to perform a contract that you have with us. For example,
          when you use our Digital Services, we will use your personal
          information to respond to your requests and provide you with such
          services.{" "}
        </>,
        <>
          <strong>Legal obligation: </strong>where it is necessary as a legal
          obligation for us to process your Personal Data in order to comply
          with a legal requirement under applicable legislation, such as when
          responding to a public or governmental body, a court, or regulators.{" "}
        </>,
        <>
          <strong>Vital Interest: </strong>where the processing is necessary to
          protect the vital interests of the data subject or of another natural
          person{" "}
        </>,
        <>
          <strong>Public interest: </strong>where the processing is necessary
          for the performance of a task carried out in the public interest or in
          the exercise of official authority vested in the data controller{" "}
        </>,
        <>
          <strong>Legitimate interest: </strong>We may use your personal
          information for our legitimate interests to improve our products and
          services, as well as the content on our Digital Services. Consistent
          with our legitimate interests and any choices that we offer or
          consents that may be required under applicable laws, we may use
          technical information and personal information for our marketing
          purposes.{" "}
        </>
      ]
    }
  },
  {
    title: "5. Cookies",
    content: (
      <>
        Cookies are small files that a site or its service provider transfers to
        a user’s computer’s hard drive through your web browser that enables the
        website or service provider’s systems to recognise your browser and
        capture and remember certain information. <br></br>
        <br></br>For full details on our cookie controls and process, please
        refer to our{" "}
        <Link to="/cookie-policy">
          <span style={{ color: "blue" }}>Cookie Policy</span>
        </Link>
        <br></br>
        <br></br>According to the laws and regulations, we are permitted to keep
        cookies on your computer if they are essential for the functioning of
        our website. We will ask for your consent for all other types of
        cookies.
      </>
    )
  },
  {
    title: "6. Your Rights",
    content:
      "Under current privacy laws and regulations, GDPR Data Laws and other Jurisdictions you possess certain rights in relation to your Personal Data, as follows:",

    list: {
      type: "ordered",
      items: [
        <>
          Right of <strong>access:</strong> you have the right to be informed
          and request access to the Personal Data we have collected and process
          about you
        </>,
        <>
          Right to <strong>rectification:</strong> you have the right to request
          the rectification of inaccurate Personal Data concerning you, which
          must be duly amended or updated without undue delay.
        </>,
        <>
          Right to <strong>erasure:</strong> you have the right to request we
          delete your Personal Data.
        </>,
        <>
          Right to <strong>restriction of processing:</strong> you have the
          right to request us to stop processing either all or some of your
          Personal Data, temporarily or permanently
        </>,
        <>
          Right to <strong>data portability:</strong> you have the right to
          request a copy of your Personal Data in electronic format and the
          right to transmit your data to another controller
        </>,
        <>
          Right to <strong>object:</strong> you have the right, at any time, to
          object to us processing your Personal Data on grounds relating to your
          particular situation, and the right to object to your Personal Data
          being processed for direct marketing purposes.
        </>,
        <>
          Right <strong>not to be subject to automated decision making:</strong>{" "}
          you have the right not to be subject to a decision based solely on
          automated processing, including profiling, which produces legal
          effects concerning you or similarly significantly affects you.
        </>
      ]
    },
    subsections: [
      {
        title: (
          <>
            <span
              className="futura"
              style={{ fontWeight: "750", textTransform: "uppercase" }}
            >
              RIGHT TO KNOW
            </span>
          </>
        ),
        content:
          "You have the right to know the specific pieces of information we collect, use, disclose or sell, and have collected, used, disclosed, or sold, covering the 12-month period preceding our receipt of your information request. In particular, you ask us to disclose:",

        list: {
          type: "ordered",
          items: [
            " The specific pieces of your Personal Information we collect or have collected",
            "The categories of Personal Information we have to collect or have collected about you",
            "The categories of sources from which we collect or have collected your Personal Information",
            "The categories of your Personal Information that we sell, or have sold, disclose, or have disclosed for a business purpose",
            "The categories of third parties to whom we sell or have sold, disclose, or have disclosed your Personal Information",
            "Commercial purpose for which we have to collect, sell, or have collected or sold your Personal Information"
          ]
        }
      },
      {
        title: (
          <>
            <span
              className="futura"
              style={{ fontWeight: "750", textTransform: "uppercase" }}
            >
              RIGHT TO Delete
            </span>
          </>
        ),
        content: (
          <>
            You also have the right to request that we delete Personal
            Information, subject to certain exceptions according to applicable
            law. You may exercise your right by emailing us at{" "}
            <a
              href="mailto:info@aime.global"
              style={{
                textDecoration: "underline"
              }}
            >
              info@aime.global
            </a>
          </>
        )
      },
      {
        title: (
          <>
            <span
              className="futura"
              style={{ fontWeight: "750", textTransform: "uppercase" }}
            >
              RIGHT TO Receive a copy
            </span>
          </>
        ),
        content: (
          <>
            You have the right to request a copy of the Personal Information we
            have collected about you. You may exercise your right by emailing us
            at{" "}
            <a
              href="mailto:info@aime.global"
              style={{
                textDecoration: "underline"
              }}
            >
              info@aime.global
            </a>
          </>
        )
      },
      {
        title: (
          <>
            <span
              className="futura"
              style={{ fontWeight: "750", textTransform: "uppercase" }}
            >
              RIGHT TO NON-DISCRIMINATION:
            </span>
          </>
        ),
        content:
          "If you exercise any of your consumer rights under the GDPR Data Laws, or other jurisdictions, you have the right not to be subjected to discrimination by us, including denial of goods or services or charging you a different price or rate for them."
      }
    ]
  },
  {
    title: "7. How to exercise your Rights",
    content: (
      <>
        You might exercise any of your rights under the GDPR Data Laws, or other
        jurisdictions by submitting your request for information required to be
        disclosed and/or deleted by contacting us at the following e-mail
        address:{" "}
        <a
          style={{
            textDecoration: "underline"
          }}
          href="mailto:info@aime.global"
        >
          info@aime.global
        </a>
        . We shall confirm the consumer making a request to know or to delete is
        the consumer about whom we have collected the Personal Information when
        we receive a verifiable consumer request. In general, we won't ask for
        more details from customers just to verify their identity. However, if
        we are unable to confirm the consumer's identification using the data we
        already hold, we may ask for more information. This information will
        only be used to confirm the consumer's identity.
      </>
    )
  },
  {
    title: "8. Who has access to your Personal Information?",
    content:
      "Being your Data Controller, we may acquire, store, process, and analyse your Personal Data in the manner and within the constraints set out above.\n\nWe do not disclose your information to third parties for personal use unless you authorise it. However, we may share it under special circumstances, including cases when:",

    list: {
      type: "unordered",
      items: [
        "  Law enforcement, government authorities, or third parties with legal rights: We may share information as may be permitted or required by the laws of any jurisdiction that may apply to us, as provided for under contract, or as we deem reasonably necessary to provide legal services. In these circumstances, we strive to take reasonable efforts to notify you before we disclose information that may reasonably identify you or your organisation, unless prior notice is prohibited by applicable law or is not possible or reasonable in the circumstances.",
        "Personal Information is required by our representatives or Service Providers who carry out tasks on our behalf",
        "We need to protect the rights, property, and safety of AIME and our employees",
        "We need to use the information for handling legal claims and disputes",
        "We need to carry out an agreement we have entered with you",
        "There is an emergency or force majeure situation"
      ]
    },
    additionalContent:
      "We may collect aggregate data about our users which is not personally identifiable and share this information with our partners, Service Providers, advertisers, and other third parties for marketing or promotional purposes.\n\nWith third parties based in nations other than your own, we might share personal data or outsource processing to them. As a result, the Personal Data we gather may be gathered, transferred to, processed, and/or stored anywhere its affiliates, or service providers. Your Personal Data may therefore be covered by privacy regulations from nations other than your own."
  },
  {
    title: "9. Website functionality",
    content:
      "We might work with Service Providers, such as people and outside companies, to run our website, offer the website on our behalf, provide website-related services, and collect analytics data. Depending on the Personal Information they have about you, Service Providers might also assist us in tailoring your experience on the website. These third parties are only entitled to use your Personal Data to do these tasks; they are not allowed to share it with anyone else or use it in any other way."
  },
  {
    title: "10. Marketing Tools",
    content:
      "Service Providers help us improve our marketing operations which are intended to engage our website’s visitors with tailored and relevant information based on previous interaction with our website. You may ask us to stop using your information for marketing reasons by contacting us via the methods provided above."
  },
  {
    title: "11. Google Analytics",
    content: (
      <>
        Google Analytics is a web analytics tool that records and analyses
        website traffic on our website to monitor how it is used. This
        information is made available to other Google services. Google may use
        it to contextualise and customise advertisements on its own network.
        <br></br>
        <br></br>In addition to information on how you use this website, Google
        Analytics may store your IP address. We, however, do not have access to
        your IP address since it is protected by Google.<br></br>
        <br></br>Visit the {" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Google Privacy Terms
        </a>{" "}
         web page to find out more about Google’s data privacy directive and
        practices.
      </>
    )
  },
  {
    title: "12. Links to third-party websites",
    content:
      "Our website may contain links to websites that are not owned or managed by us. When you follow a third-party link, you will leave our website and be sent to the third party's website. We have no supervision over and do not accept responsibility for any of the third party's practices, content, or services provided on their website."
  },
  {
    title: "13. Data retention and deletion",
    content:
      "Your Personal Data will be retained for as long as is required to achieve the purposes outlined in this Policy. If you ask us to do so, we will remove your personal information unless we are legally forced to do otherwise, such as to comply with a court order, settle a legal dispute, etc.\n\nPlease keep in mind that we may require you to confirm your identity before we reply to your requests to amend, delete, or restrict the usage of your Personal Data. If we are unable to offer access within a sensible time frame, we will advise you of a date when the information will be made available. We will provide an explanation if access is restricted for any reason. If you wish to know what Personal Information, we store about you or would like it to be removed, please contact us using the methods provided in this Privacy Policy. If you have any concerns about how we process your personal data, we ask that you contact us to resolve the issue. (see Part 17 of this Policy for further details concerning the erasure of personal information)"
  },
  {
    title: "14. Keeping Your Personal Data Safe",
    content:
      "We are firmly committed to the security of your Personal Data. We will make our best efforts to protect your Personal Data, by implementing adequate technical, physical, administrative, and organisational measures, in order to guarantee the security of your information.\n\nWe put in place administrative, technical, and physical precautions that are economically feasible in order to abide by applicable legal obligations and protect the data we collect. This includes requesting written guarantees from third parties who may have access to your personal information that they will safeguard it with measures meant to offer a level of protection comparable to those taken by our Business, where necessary or relevant and practical. No information system, however, can be completely safe. Your information's complete security is not something we can guarantee. Furthermore, we are not liable for the security of data that you send to us across networks that are not under our control, such as wireless networks and the Internet.\n\nWe have put in place a number of things, like (encryption, pseudonymisation, amongst other measures to protect your information). Additionally, we advise you to choose a secure password, avoid sharing it with anybody, and log out of your account as soon as your session is over in order to protect your personal data.\n\nThe information we gather may be kept on file in the US or other nations where we or our service providers have locations. We may transfer information to nations other than your home country, including the United States, which may have different data protection laws and regulations than your nation."
  },
  {
    title: "15. Data Subject Access",
    content:
      "Data subjects may make subject access requests (“SARs”) at any time to find out more about the personal data which AIME holds about them, what it is doing with that personal data, and why.\n\nData subjects wishing to make a SAR may do so in writing, using AIME’s Subject Access Request Form, or other written communication.\n\nResponses to SARs shall normally be made within one month of receipt, however this may be extended by up to two months if the SAR is complex and/or numerous requests are made. If such additional time is required, the data subject shall be informed.\n\nAll SARs received shall be handled by AIME’s management team.\n\nAIME does not charge a fee for the handling of normal SARs. AIME reserves the right to charge reasonable fees for additional copies of information that has already been supplied to a data subject, and for requests that are manifestly unfounded or excessive, particularly where such requests are repetitive."
  },
  {
    title: "16. Rectification of Personal Data",
    content:
      "Data subjects have the right to require AIME to rectify any of their personal data that is inaccurate or incomplete.\n\nAIME shall rectify the personal data in question, and inform the data subject of that rectification, within one month of the data subject informing AIME of the issue. The period can be extended by up to two months in the case of complex requests. If such additional time is required, the data subject shall be informed.\n\nIn the event that any affected personal data has been disclosed to third parties, those parties shall be informed of any rectification that must be made to that personal data."
  },
  {
    title: "17. Erasure of Personal Data",
    content:
      "Data subjects have the right to request that AIME erases the personal data it holds about them in the following circumstances:",

    list: {
      type: "unordered",
      items: [
        "It is no longer necessary for AIME to hold that personal data with respect to the purpose(s) for which it was originally collected or processed",
        "The data subject wishes to withdraw their consent to AIME holding and processing their personal data",
        " The data subject objects to AIME holding and processing their personal data (and there is no overriding legitimate interest to allow AIME to continue doing so) (see Part 20 of this Policy for further details concerning the right to object)",
        " The personal data has been processed unlawfully",
        " The personal data needs to be erased in order for AIME to comply with a particular legal obligation."
      ]
    },
    additionalContent:
      "Unless AIME has reasonable grounds to refuse to erase personal data, all requests for erasure shall be complied with, and the data subject informed of the erasure, within one month of receipt of the data subject’s request. The period can be extended by up to two months in the case of complex requests. If such additional time is required, the data subject shall be informed.\n\nIn the event that any personal data that is to be erased in response to a data subject’s request has been disclosed to third parties, those parties shall be informed of the erasure (unless it is impossible or would require disproportionate effort to do so)."
  },

  {
    title: "18. Restriction of Personal Data Processing",
    content:
      "Data subjects may request that AIME ceases processing the personal data it holds about them. If a data subject makes such a request, AIME shall retain only the amount of personal data concerning that data subject (if any) that is necessary to ensure that the personal data in question is not processed further. In the event that any affected personal data has been disclosed to third parties, those parties shall be informed of the applicable restrictions on processing it (unless it is impossible or would require disproportionate effort to do so)."
  },
  {
    title: "19. Data Portability",
    content:
      "Where data subjects have given their consent to AIME to process their personal data (whether in an automated manner or any other such processing means) or the processing is otherwise required for the performance of a contract between AIME and the data subject, data subjects have the right, under the GDPR and other Jurisdictions, to receive a copy of their personal data and to use it for other purposes (namely transmitting it to other data controllers).\n\nTo facilitate the right of data portability, AIME shall make available all applicable personal data to data subjects in plain text format.\n\nAll requests for copies of personal data shall be complied with within one month of the data subject’s request. The period can be extended by up to two months in the case of complex or numerous requests. If such additional time is required, the data subject shall be informed.\n\n(see Part 10 of this Policy for further details concerning Subject Access Requests)"
  },
  {
    title: "20. Objections to Personal Data Processing",
    content:
      "Data subjects have the right to object to AIME processing their personal data based on legitimate interests, direct marketing (including profiling), and processing for scientific and/or historical research and statistics purposes.\n\nWhere a data subject objects to AIME processing their personal data based on its legitimate interests, AIME shall cease such processing immediately, unless it can be demonstrated that AIME’s legitimate grounds for such processing override the data subject’s interests, rights, and freedoms, or that the processing is necessary for the conduct of legal claims.\n\nWhere a data subject objects to AIME processing their personal data for direct marketing purposes, AIME shall cease such processing immediately.\n\nWhere a data subject objects to AIME processing their personal data for scientific and/or historical research and statistics purposes, the data subject must, under the GDPR, “demonstrate grounds relating to his or her particular situation”. AIME is not required to comply if the research is necessary for the performance of a task carried out for reasons of public interest."
  },
  {
    title: "21. Data Breaches",
    content:
      "Despite our best efforts to secure your information if we suffer a data breach, we will do our best to reduce its effects and will follow the applicable notification provision of the GDPR and any other applicable laws within other Jurisdictions."
  },
  {
    title: "22. Changes to this Privacy Notice",
    content:
      "AIME may update this Notice periodically and will revise the date at the top of this Notice to reflect the date when such update occurred. If we make any material changes in the way we collect, use, and/or share the personal information that you have provided, we will endeavour to provide you with notice before such changes take effect, such as by posting prominent notice on our website."
  },
  {
    title: "23. Third Party Processors",
    content: (
      <>
        Our carefully selected partners and service providers may process
        personal information about you on our behalf as described below:
        <br></br>
        <br></br>
        Digital Marketing Service Providers
        <br></br>
        <br></br>
        We periodically appoint digital marketing agents to conduct marketing
        activity on our behalf, such activity may result in the compliant
        processing of personal information. Our appointed data processors
        include:
        <br></br>
        <br></br>
        (i)Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can
        contact Sopro and view their privacy policy here:{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="http://sopro.io"
          target="_blank"
          rel="noreferrer"
        >
          http://sopro.io.
        </a>{" "}
        Sopro are registered with the ICO Reg: ZA346877 their Data Protection
        Officer can be emailed at:{" "}
        <a href="mailto:dpo@sopro.io" style={{ textDecoration: "underline" }}>
          dpo@sopro.io.
        </a>
      </>
    )
  }
];
