import React from "react";
import {
  Button,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import VectorDesign from "../assets/images/Vector_design3.svg";
import styles from "../assets/styles/AppStyle";
import CButton2 from "./common/CButton2";
import ModalGetInTouch from "./ModalGetInTouch";
import { ReactComponent as Logo2 } from "../assets/images/Logo2.svg";
import { ReactComponent as LinkedInLogo } from "../assets/images/LinkedIn - Negative.svg";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <section
      style={{
        color: "white",
        backgroundColor: "#1E2841",
      }}
    >
      <ModalGetInTouch isOpen={isOpen} onClose={onClose} />
      <Stack pb={["60px"]} bg={"#1E2841"} spacing={0}>
        <Stack
          spacing={0}
          minH={"560px"}
          bgImage={`linear-gradient(180deg, rgba(30, 40, 65, 0) 50%, #1E2841 100%),url(${VectorDesign})`}
          bgSize="initial"
          bgPosition="top left"
          bgRepeat={"no-repeat"}
          pt={["60px", "60px", "60px", "140px"]}
          pl={["32px", "32px", "32px", "120px"]}
          pr={["32px", "32px", "60px", "120px"]}
        >
          <Stack spacing={"60px"} justify={"center"} align={"center"}>
            <Stack spacing={"24px"} maxW={["100%", "790px"]}>
              <Text
                className="futura"
                textAlign={"center"}
                bgGradient="linear(to-r, #F75BB9 0%, #3953D4 100%)"
                bgClip="text"
                // style={styles.textHeading2}
                fontWeight={750}
                fontSize={["28px", "28px", "32px", "32px"]}
                lineHeight={["40px", "40px", "60px", "60px"]}
                textTransform={"uppercase"}
              >
                Bring your ideas to life
              </Text>
              <Text
                textAlign={"center"}
                fontWeight={400}
                fontSize={["18px", "18px", "20px", "20px"]}
                lineHeight={["32px", "32px", "40px", "40px"]}
                color={"#FFFFFF"}
              >
                If your business operates in any of these spaces, or if you have
                an idea you’ve been itching to get started, we’d love to speak
                to you about how we can help.
              </Text>
            </Stack>
            <CButton2
              onClick={onOpen}
              label={"Get in touch"}
              px={"52px"}
              height={"56px"}
            />
          </Stack>
        </Stack>

        <Stack
          pt={["78px", "0px", "0px", "0px"]}
          pl={["32px", "32px", "32px", "120px"]}
          pr={["32px", "32px", "32px", "120px"]}
          spacing={"24px"}
          align={"center"}
        >
          <Logo2 />
          <Text style={styles.bodyText4}>Copyright © Aime 2023</Text>

          <div
            style={{
              gap: 12,
              display: "inline-flex",
            }}
          >
            <Button
              onClick={() => navigate("/cookie-policy")}
              px={6}
              py={1}
              justifyContent={"center"}
              lineHeight={"32px"}
              fontSize="16px"
              fontFamily="Sailec"
              color="white"
              background="rgba(255, 255, 255, 0.05)"
              borderRadius={100}
              fontWeight={"400"}
              _hover={{ background: "rgba(255, 255, 255, 0.05)" }}
            >
              Cookie Policy
            </Button>

            <Button
              onClick={() => navigate("/privacy-policy")}
              href="/privacy-policy"
              px={6}
              py={1}
              justifyContent={"center"}
              lineHeight={"32px"}
              fontSize="16px"
              fontFamily="Sailec"
              color="white"
              background="rgba(255, 255, 255, 0.05)"
              borderRadius={100}
              fontWeight={"400"}
              _hover={{ background: "rgba(255, 255, 255, 0.05)" }}
            >
              Privacy Policy
            </Button>
          </div>
          <Flex>
            <Link
              href="https://www.linkedin.com/company/aimeglobal/?originalSubdomain=uk"
              isExternal
            >
              <LinkedInLogo />
            </Link>
          </Flex>
        </Stack>
      </Stack>
    </section>
  );
}
